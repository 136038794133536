import {
  CART_ITEM_ADD,
  CART_ITEM_REMOVE,
  CART_ITEM_ADDONS_UPDATE,
  CART_COUPON_UPDATE,
  CART_DELIVERY_ADDRESS_UPDATE,
  CART_DELIVERY_CHARGE_UPDATE,
  CART_DELIVERY_DATE_UPDATE,
  CART_DELIVERY_TIME_UPDATE,
  CART_ORDER_TYPE_UPDATE,
  CART_ORDER_NOTE_UPDATE,
  CART_CLEAR,
  CART_PAYMENT_AMOUNT_UPDATE,
  CART_PAYMENT_METHOD_UPDATE,
  CART_PAYMENT_STATUS_UPDATE,
  CART_UPDATE_ITEMS_TOTAL,
  CART_UPDATE_ADDONS_TOTAL,
  CART_DISCOUNT_UPDATE,
  CART_UPDATE_ASAP_TIME,
  CLEAR_COUPON,
  CLEAR_DISCOUNT,
  CART_ORDER_POINTS,
  CART_POINTS_TO_MONEY,
  CART_ADJUST_POINT_MONEY,
} from './cartTypes';
import { moneyFormat } from '../../util/util';

const defaultState = {
  items: [],
  addons: [],
  coupon: {
    value: 0,
    code: '',
    type: '',
    amount: 0,  // 50BFJKS
  },
  discount: {
    amount: 0,
    type: '',
    value: 0, // 10%, 10.50
  },
  delivery: {
    address: {},
    charge: 0,
    distance: 0,
    date: '',
    time: '',
    isAsapTime: true,
  },
  order: {
    type: 'Delivery',
    note: ''
  },
  itemsTotal: 0,
  addonsTotal: 0,
  orderPoint: 0,
  pointsToMoney: 0,
  adjustPointMoney: 0,
  payment: {
    amount: 0,
    method: '',
    status: false
  }
};

const reducer = (state = defaultState, { type, payload }) => {

  switch (type) {

    case CART_ITEM_ADD:
      return {
        ...state,
        items: [...itemAdd(state.items, payload)],
      }

    case CART_ITEM_REMOVE:
      return {
        ...state,
        items: [...itemRemove(state.items, payload)],
      }

    case CART_CLEAR:
      return {
        ...defaultState
      }

    case CLEAR_COUPON:
      return {
        ...state,
        coupon: defaultState.coupon,
      }

    case CLEAR_DISCOUNT:
      return {
        ...state,
        discount: defaultState.discount,
      }

    case CART_UPDATE_ITEMS_TOTAL:
      return {
        ...state,
        itemsTotal: calculateItemsTotal(state.items),
      }

    case CART_UPDATE_ADDONS_TOTAL:
      return {
        ...state,
        addonsTotal: calculateAddonsTotal(state.addons, state.items),
      }

    case CART_ITEM_ADDONS_UPDATE:
      return {
        ...state,
        addons: [...itemAddonsUpdate(state.addons, payload.addons, payload.item)],
      }

    case CART_COUPON_UPDATE:
      return {
        ...state,
        coupon: payload
      }

    case CART_DISCOUNT_UPDATE:
      return {
        ...state,
        discount: payload
      }

    case CART_DELIVERY_ADDRESS_UPDATE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          address: payload
        }
      }

    case CART_DELIVERY_CHARGE_UPDATE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          distance: payload.distance,
          charge: payload.charge,
        }

      }

    case CART_DELIVERY_DATE_UPDATE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          date: payload
        }
      }

    case CART_DELIVERY_TIME_UPDATE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          time: payload
        }
      }

    case CART_UPDATE_ASAP_TIME:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          isAsapTime: payload
        }
      }

    case CART_ORDER_TYPE_UPDATE:
      return {
        ...state,
        order: {
          ...state.order,
          type: payload,
        }
      }

    case CART_ORDER_NOTE_UPDATE:
      return {
        ...state,
        order: {
          ...state.order,
          note: payload,
        }
      }

    case CART_PAYMENT_AMOUNT_UPDATE:
      return {
        ...state,
        payment: {
          ...state.payment,
          amount: payload
        }
      }

    case CART_PAYMENT_METHOD_UPDATE:
      return {
        ...state,
        payment: {
          ...state.payment,
          method: payload
        }
      }

    case CART_PAYMENT_STATUS_UPDATE:
      return {
        ...state,
        payment: {
          ...state.payment,
          status: payload
        }
      }
    case CART_ORDER_POINTS:
      return {
        ...state,
        orderPoint: payload,
      }
    case CART_POINTS_TO_MONEY:
      return {
        ...state,
        pointsToMoney: payload,
      }
    case CART_ADJUST_POINT_MONEY:
      return {
        ...state,
        adjustPointMoney: payload,
      }

    default: return state
  }

};

export default reducer;


function itemAdd(items, item) {
  const existingItemIndex = items.findIndex(itm => itm.id === item.id);

  // if this is a new item & there's no item
  if (existingItemIndex === -1 && !items.length) {
    return [
      {
        ...item,
        qty: 1
      }
    ];
  }

  // if this is a new item & there's items available
  if (existingItemIndex === -1) {
    return [
      {
        ...item,
        qty: 1
      },
      ...items
    ];
  }

  // replace old item with new one that has updated qty
  const existingItem = items[existingItemIndex];
  const newItem = {
    ...existingItem,
    qty: (existingItem.qty + 1)
  }

  items.splice(existingItemIndex, 1, newItem);
  return items;
}

function itemRemove(items, item) {

  const existingItemIndex = items.findIndex(itm => itm.id === item.id);

  // if item not found just return all items
  if (existingItemIndex === -1) {
    return items;
  }

  const existingItem = items[existingItemIndex];

  // if item has more than 1 qty then deduct one
  if (existingItem.qty > 1) {

    const newItem = {
      ...existingItem,
      qty: (existingItem.qty - 1)
    };

    items.splice(existingItemIndex, 1, newItem);

  } else { // or remove the item

    items.splice(existingItemIndex, 1);
  }

  return items;
}

function itemAddonsUpdate(stateAddons, addons, item) {

  const otherItemAddons = stateAddons.filter(addon => addon.item_id !== item.id);

  // if addons are empty, we'll assume to remove item addons
  if (!addons.length) {
    return otherItemAddons;
  }

  // merge new addons & return
  const newAddons = addons.map(addon => ({
    ...addon,
    item_id: item.id
  }));

  return [
    ...otherItemAddons,
    ...newAddons
  ];
}

function calculateItemsTotal(items) {
  const total = items.reduce((acc, itm) => {
    return acc + (itm.price * itm.qty);
  }, 0);

  return total;
}

function calculateAddonsTotal(addons, items) {
  const total = addons.reduce((acc, addon) => {

    const addonItem = items.find(item => item.id === addon.item_id);

    return acc + (addon.price * addonItem.qty);
  }, 0);

  return total;
}
